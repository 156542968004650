export var organizers = [
	{
		name: "Prof. Dr. Gerhard P. Fettweis",
		affiliation: "TU&nbsp;Dresden /<br>Barkhausen Institut",
		portrait: "GerhardFettweis.jpg",
		role: "General<br>Chair",
	},

	// {
	// 	name: "Prof. Dr. Frank Fitzek",
	// 	affiliation: "TU&nbsp;Dresden",
	// 	portrait: "FrankFitzek.jpg",
	// 	role: "General<br>Co-Chair",
	// },

	{
		name: "Dr. Wahab Almuhtadi",
		affiliation: "IEEE ComSoc",
		portrait: "wahab.jpg",
		role: "General<br>Chair",
	},
	{
		name: "Prof. Dr. Holger Blume",
		affiliation: "Leibniz University Hannover",
		portrait: "Holger_Blume.jpg",
		role: "General<br>Vice-Chair",
	},
	{
		name: "Jens Drews",
		affiliation: "GlobalFoundries",
		portrait: "Jens_Drews.jpg",
		role: "General<br>Vice-Chair",
	},
	{
		name: "Prof. Dr. Jan M. Rabaey",
		affiliation: "University of California at Berkeley",
		portrait: "Jan_Rabaey.jpg",
		role: "General<br>Vice-Chair",
	},
	{
		name: "Dr. Gerd Teepe",
		affiliation: "Celtro GmbH",
		portrait: "Gerd_Teepe.png",
		role: "General<br>Vice-Chair",
	},
	{
		name: "Dr. Rico Radeke",
		affiliation: "TU&nbsp;Dresden",
		portrait: "RicoRadeke.jpg",
		role: "Finance<br>Chair",
	},
	{
		name: "Dr. Tianxiang Dai",
		affiliation: "Lancaster University Leipzig",
		portrait: "Tianxiang_Dai.jpg",
		role: "Program<br>Co-Chair",
	},
	{
		name: "Dr. Michael Raitza",
		affiliation: "Barkhausen&nbsp;Institut",
		portrait: "Michael_Raitza.jpg",
		role: "Program<br>Co-Chair",
	},
	//{
	//	name: "Dr. Hans-Peter Bernhard",
	//	affiliation: "Silicon Austria Labs / JKU Linz",
	//	portrait: "Hans-Peter Bernhard.jpg",
	//	role: "Program<br>Co-Chair",
	//},
	// {
	// 	name: "Florian Gast",
	// 	affiliation: "TU&nbsp;Dresden",
	// 	portrait: "florian-gast.jpg",
	// 	role: "Startup & Technical<br> Arrangements Chair",
	// },
	{
		name: "Nicole Flechs",
		affiliation: "TU&nbsp;Dresden",
		portrait: "nicole_flechs.jpg",
		role: "Industry Relations<br>Chair",
	},
	//{
	//	name: "Carolin Bauder",
	//	affiliation: "TU&nbsp;Dresden",
	//	portrait: "CarolinBauder.jpg",
	//	role: "Industry Relations<br>Co-Chair",
	//},
	//{
	//	name: "Shangqing Wang",
	//	affiliation: "TU&nbsp;Dresden",
	//	portrait: "shangqing_wang.png",
	//	role: "Sustainability<br>Co-Chair",
	//},
	{
		name: "Berenike Heinrich-Männchen",
		affiliation: "TU&nbsp;Dresden",
		portrait: "berenike_heinrich-maennchen.jpg",
		role: "Local Arrangements<br>Co-Chair",
	},
	{
		name: "Claudia Hoffsky",
		affiliation: "TU&nbsp;Dresden",
		portrait: "claudia_hoffsky.jpg",
		role: "Local Arrangements<br>Co-Chair",
	},
	//{
	//	name: "Sandy Kratochwil",
	//	affiliation: "TU&nbsp;Dresden",
	//	portrait: "sandy.jpg",
	//	role: "Local Arrangements<br>Chair",
	//},


	// {
	// 	name: "Francisco Arroyo",
	// 	affiliation: "5G Lab Germany",
	// 	portrait: "francisco_arroyo.jpg",

	// },

	// {
	// 	name: "Fabian Diehm",
	// 	affiliation: "Barkhausen&nbsp;Institut",
	// 	portrait: "fabian_diehm.jpg",
	// 	role: "Web<br>Chair",
	// },

];
